import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  MenuButton,
  MenuItem,
  MenuList,
  Menu,
  Text,
  Image,
  Heading,
  List,
  ListItem,
  useColorMode,
  useColorModeValue,
  IconButton,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "./redux/slices/languageSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowUp,
  faChevronDown,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import logoBlack from "./assets/img/sicilianologoblack.png";
import logoWhite from "./assets/img/sicilianologo1.png";

import halalLogo from "./assets/img/halallllogo.png";
import wolt from "./assets/img/woltplls.png";

import { NavLink, Outlet } from "react-router-dom";

import ScrollToTop from "react-scroll-to-top";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const languageOptions = {
  az: "AZ",
  en: "EN",
  ru: "RU",
};

export default function Layout() {
  const { t, i18n } = useTranslation("global");
  const language = useSelector((state) => state?.language.language);
  const dispatch = useDispatch();
  const [currentCategoryItems, setCurrentCategoryItems] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };
  const resetCategoryItems = () => {
    setCurrentCategoryItems([]);
    setSelectedCategoryName("");
  };
  const activeStyle = {
    backgroundColor: "blue",
    color: "white",
  };
  const getMaxWidthFortabs = (language) => {
    switch (language) {
      case "az":
        return "560px";
      case "en":
        return "560px";
      case "ru":
        return "560px";
      //320px
      default:
        return "560px";
    }
  };

  const { toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue("#F9F9F9", "black");
  const bgColorTabBorder = useColorModeValue("#f7906c", "white");

  const linkColor = useColorModeValue("green", "white");
  const hoverColor = useColorModeValue("#E1306C", "#E1306C");
  const footerColor = useColorModeValue("#716b68", "white");

  const restaurantLogo = useColorModeValue(logoBlack, logoWhite);

  const MoonIcon = () => (
    <svg viewBox="0 0 24 24" width="24" height="24">
      <path
        fill="currentColor"
        d="M9.37,5.51C9.19,6.15,9.1,6.82,9.1,7.5c0,4.08,3.32,7.4,7.4,7.4c0.68,0,1.35-0.09,1.99-0.27C17.45,17.19,14.93,19,12,19 c-3.86,0-7-3.14-7-7C5,9.07,6.81,6.55,9.37,5.51z M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36 c-0.98,1.37-2.58,2.26-4.4,2.26c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z"
      ></path>
    </svg>
  );

  const SunIcon = () => (
    <svg viewBox="0 0 24 24" width="24" height="24">
      <path
        fill="currentColor"
        d="M12,9c1.65,0,3,1.35,3,3s-1.35,3-3,3s-3-1.35-3-3S10.35,9,12,9 M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5 S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1 s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0 c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95 c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41 L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41 s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06 c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z"
      ></path>
    </svg>
  );

  return (
    <Box w="100%">
      <style>
        {`body { background-color: ${bgColor}; font-family:Comfortaa, Arial}`}
      </style>
      <VStack
        maxW="560px"
        margin={"0 auto"}
        gap={"16px"}
        // border="1px solid red"
        backgroundSize={"cover"}
        height="255px"
      >
        <Flex
          // maxW="560px"
          w={"100%"}
          // border="1px solid green"
          m="0 auto"
          pt="12px"
          pr="8px"
          alignItems="end"
          justifyContent="end"
          gap={"20px"}
        >
          {" "}
          <Menu>
            <MenuButton
              boxShadow=" 1px 1px 3px rgba(0, 0, 0, 0.35)"
              display="flex"
              // w="62px"
              padding="0 16px"
              pt="0"
              h="28px"
              bgColor="#f7f8fa"
              color="#212529"
              borderRadius="16px"
              // colorScheme="teal"
              as={Button}
            >
              <Flex gap="4px" alignItems="center" justifyContent="center">
                <Text>{languageOptions[language]}</Text>
                <FontAwesomeIcon fontSize="16px" icon={faChevronDown} />
              </Flex>
            </MenuButton>
            <MenuList minW="73px">
              <MenuItem
                style={language === "az" ? activeStyle : {}}
                onClick={() => handleLanguageChange("az")}
                fontWeight="500"
              >
                AZ
              </MenuItem>
              <MenuItem
                style={language === "en" ? activeStyle : {}}
                onClick={() => handleLanguageChange("en")}
                fontWeight="500"
              >
                EN
              </MenuItem>
              <MenuItem
                style={language === "ru" ? activeStyle : {}}
                onClick={() => handleLanguageChange("ru")}
                fontWeight="500"
              >
                RU
              </MenuItem>
            </MenuList>
          </Menu>
          <IconButton
            aria-label="Mode Change"
            variant="outline"
            colorScheme="black"
            size="sm"
            icon={useColorModeValue(<MoonIcon />, <SunIcon />)}
            onClick={toggleColorMode}
          />
        </Flex>
        <Box
          margin="0 auto"
          // mt="-12px"
          padding="0 2px"
        >
          <Center mt="-6px" mb="6px">
            {" "}
            <Image
              // border="3px solid white"
              // outline="2px solid #fd7e14"
              borderRadius="50%"
              // boxSize="90px"
              w="160px"
              src={restaurantLogo}
            />
          </Center>
          <Heading
            w="100%"
            fontFamily="Comfortaa, Arial"
            p="4px 0"
            // boxShadow=" 1px 1px 3px rgba(0, 0, 0, 0.35)"
            textAlign="center"
            fontSize="26px" //30px
            fontWeight="600"
            // color="black"
            sx={{
              "@media (max-width: 290px)": {
                fontSize: "22px", //30px
              },
            }}
          >
            Siciliano Restaurant
          </Heading>
          <Text
            // boxShadow="1px 1px 3px rgba(0, 0, 0, 0.35)"
            textAlign="center"
            fontSize="16px"
            // color="black"
            p="0 2px"
            pb="2px"
            sx={{
              "@media (max-width: 290px)": {
                fontSize: "12px", //30px
              },
            }}
          >
            <FontAwesomeIcon fontSize="20px" icon={faLocationDot} />{" "}
            <Text
              sx={{
                whiteSpace: "nowrap",
              }}
              as="span"
            >
              {" "}
              {t("address")}{" "}
            </Text>{" "}
            <Text
              sx={{
                whiteSpace: "nowrap",
              }}
              as="span"
            >
              {" "}
              {t("address2")}{" "}
            </Text>
          </Text>
        </Box>
      </VStack>

      {selectedCategoryName && (
        <Box
          onClick={resetCategoryItems}
          position={"fixed"}
          border="none"
          borderRadius="50%"
          padding="10px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          boxShadow=" 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05)"
          zIndex="10"
          top="12px "
          left="9px "
          cursor="pointer"
          boxSize="40px"
          bgColor="#FFFFFF"
        >
          <FontAwesomeIcon
            fontWeight="900"
            color="#4c4c4c"
            fontSize="17px"
            icon={faArrowLeft}
          />
        </Box>
      )}

      <Box
        // bgColor="#FFFFFF"
        boxShadow=" 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05)"
        display="flex"
        flexDirection="column"
        padding="24px 16px 24px 16px"
        position="relative"
        // bg="white"
        borderRadius="24px 24px 0 0"
        maxW={getMaxWidthFortabs(language)}
        // minW="300px"
        margin="0 auto"
        mt="-32px"
        // border="1px solid red"
      >
        <Flex
          // maxW={getMaxWidthFortabs(language)}????????????
          justifyContent="space-between"
          alignItems="center"

          // sx={{
          //   "@media (max-width: 340px)": {
          //     display: "block",
          //   },
          // }}
        >
          <List
            // mt="12px"
            // border="1px solid blue"
            display="flex"
            // overflowX={{ base: "auto", md: "unset" }}
            // overflowY="hidden"
            alignItems="center"
            gap="12px"
            sx={{
              "@media (max-width: 425px)": {
                gap: "8px",
              },
              "@media (max-width: 340px)": {
                // flexDirection: "column",
                gap: "6px",
              },
            }}
            // mb="16px"
          >
            <NavLink
              to="/menu"
              // className={({ isActive }) => (isActive ? syles.active : "")}
            >
              {({ isActive }) => (
                <ListItem
                  style={{ color: isActive ? "#ffffff" : "#f7906c" }}
                  transition=".3s"
                  _hover={{ bg: "#f7906c", color: "white !important" }}
                  // p="0px 12px"
                  // pl="12px"
                  // pr="12px"
                  w="100px"
                  // p="0px 12px"
                  // h="32px"
                  fontSize="18px !important"
                  textAlign="center"
                  borderRadius="32px"
                  fontWeight="400 !important"
                  border="3px solid #f7906c"
                  bg={isActive ? "#f7906c" : "transparent"}
                  sx={{
                    // Responsive styles
                    // "@media (max-width: 600px)": {
                    //   fontSize: "14px !important",
                    //   p: "0px 10px",
                    //   h: "30px",
                    // },
                    "@media (max-width: 425px)": {
                      fontSize: "16px !important",
                      // p: "0px 12px",
                      w: "90px",
                    },
                    "@media (max-width: 405px)": {
                      fontSize: "16px !important",
                      // p: "0px 11px",
                      w: "85px",
                    },
                    "@media (max-width: 380px)": {
                      fontSize: "15px !important",
                      // p: "0px 10px",
                      w: "85px",
                      border: "2px solid #f7906c ",
                    },
                    "@media (max-width: 340px)": {
                      fontSize: "14px !important",
                      // p: "0px 10px",
                      w: "68px",
                      border: "2px solid #f7906c ",
                      // w: "130px",
                      // fontSize: "16px !important",
                    },
                    "@media (max-width: 305px)": {
                      fontSize: "13px !important",
                      w: "69px",
                      border: "2px solid #f7906c ",
                    },
                  }}
                >
                  {t("tab.tab1")}
                </ListItem>
              )}
            </NavLink>
            <NavLink
              to="/dessert"
              // className={({ isActive }) => (isActive ? syles.active : "")}
            >
              {({ isActive }) => (
                <ListItem
                  style={{ color: isActive ? "#ffffff" : "#f7906c" }}
                  transition=".3s"
                  _hover={{ bg: "#f7906c", color: "white !important" }}
                  // p="0px 12px"
                  // h="32px"
                  textAlign="center"
                  p="0px"
                  // h="32px"
                  w="120px"
                  // w="110px"
                  borderRadius="32px"
                  fontWeight="400 !important"
                  border="3px solid #f7906c"
                  fontSize="18px !important"
                  bg={isActive ? "#f7906c" : "transparent"}
                  sx={{
                    "@media (max-width: 425px)": {
                      fontSize: "16px !important",
                      // p: "0px 12px",
                      w: "110px",
                    },
                    "@media (max-width: 405px)": {
                      fontSize: "16px !important",
                      // p: "0px 11px",
                      w: "105px",
                    },
                    "@media (max-width: 380px)": {
                      fontSize: "15px !important",
                      // p: "0px 10px",
                      w: "90px",
                      border: "2px solid #f7906c ",
                    },
                    "@media (max-width: 340px)": {
                      // w: "130px",
                      fontSize: "14px !important",
                      // p: "0px 10px",
                      w: "80px",
                      // fontSize: "16px !important",
                    },
                    "@media (max-width: 305px)": {
                      fontSize: "13px !important",
                      w: "74px",
                    },
                  }}
                >
                  {t("tab.tab2")}
                </ListItem>
              )}
            </NavLink>
            <NavLink
              to="/bar"
              // className={({ isActive }) => (isActive ? syles.active : "")}
            >
              {({ isActive }) => (
                <ListItem
                  w="80px"
                  textAlign="center"
                  sx={{
                    "@media (max-width: 425px)": {
                      fontSize: "16px !important",
                      // p: "0px 12px",
                      w: "90px",
                    },
                    "@media (max-width: 405px)": {
                      fontSize: "16px !important",
                      // p: "0px 11px",
                      w: "66px",
                    },
                    "@media (max-width: 380px)": {
                      fontSize: "15px !important",
                      // p: "0px 10px",
                      w: "60px",
                      border: "2px solid #f7906c ",
                    },
                    "@media (max-width: 340px)": {
                      // w: "130px",
                      fontSize: "14px !important",
                      // p: "0px 10px",
                      w: "58px",
                      // fontSize: "16px !important",
                    },
                    "@media (max-width: 305px)": {
                      fontSize: "13px !important",
                      w: "50px",
                    },
                  }}
                  style={{ color: isActive ? "#ffffff" : "#f7906c" }}
                  transition=".3s"
                  _hover={{ bg: "#f7906c", color: "white !important" }}
                  // h="32px"
                  borderRadius="32px"
                  fontWeight="400 !important"
                  border="3px solid #f7906c"
                  fontSize="18px !important"
                  bg={isActive ? "#f7906c" : "transparent"}
                >
                  {t("tab.tab3")}
                </ListItem>
              )}
            </NavLink>
          </List>

          <Image
            // border="1px solid red"
            // top=".5%"
            // right="2%"
            // position="absolute"
            boxSize="50px"
            sx={{
              "@media (max-width: 380px)": {
                boxSize: "46px",
              },

              "@media (max-width: 340px)": {
                // top: ".5%",
                // right: "6%",
                // position: "absolute",
                boxSize: "42px",
              },
            }}
            src={halalLogo}
          />
        </Flex>
        <Outlet />
        <Flex gap="8px" mt="2rem" justifyContent="end" alignItems="center">
          <a
            href="https://www.instagram.com/siciliano.az/"
            //   target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              // color: "green",
              color: linkColor,
              transition: "color 0.3s",
            }}
            // onMouseEnter={(e) => (e.currentTarget.style.color = "#E1306C")}
            // onMouseLeave={(e) => (e.currentTarget.style.color = "green")}
            onMouseEnter={(e) => (e.currentTarget.style.color = hoverColor)}
            onMouseLeave={(e) => (e.currentTarget.style.color = linkColor)}
          >
            <FontAwesomeIcon fontSize="30px" icon={faInstagram} />
          </a>{" "}
          <Image alignItems="end" h="40px" w="80px" src={wolt} />
        </Flex>
        <Text
          fontSize="16px"
          textAlign="end"
          mt="12px"
          color={footerColor}
          // color="#716b68"
        >
          {t("mobile")}:{" "}
          <Text
            sx={{
              whiteSpace: "nowrap",
              // overflow: "hidden",
              // textOverflow: "ellipsis",
            }}
            as="span"
          >
            {" "}
            <a
              style={{
                color: "inherit",
                transition: "color 0.3s",
              }}
              href="tel:+994509813333"
              onMouseEnter={(e) => (e.currentTarget.style.color = "red")}
              onMouseLeave={(e) => (e.currentTarget.style.color = "inherit")}
            >
              (+994) 50-981-33-33{" "}
            </a>
          </Text>
        </Text>

        <Text
          fontSize="14px"
          textAlign="end"
          mt="16px"
          mb="14px"
          color="#716b68"
        >
          {t("halal")}
          {/* Bütün məhsullar İslam və şəriət qaydalarına uyğun kəsilib. */}
        </Text>

        <ScrollToTop
          smooth
          component={
            <FontAwesomeIcon icon={faArrowUp} fontSize="16px" color="#4c4c4c" />
          }
          style={{
            borderRadius: "50%",
            // boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.05)",
            boxShadow: "0px 0px 12px 2px rgba(173,172,172,0.76)",
            bottom: "32px",
            right: "16px",
            backgroundColor: "#ffffff",
            width: "40px",
            height: "40px",
            transition: "opacity 0.5s ease-in-out",
            opacity: 0.7,
            zIndex: "10",
          }}
        />
      </Box>
    </Box>
  );
}
